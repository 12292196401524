<template>
    <footer id="footer-bar">
        <p>© 2021 John Feigel</p>
    </footer>
</template>

<script>
export default {
    name: 'FooterBar'
}
</script>

<style>
    #footer-bar{
        background-color: #39496A;
        color: #469fec;
    }
</style>